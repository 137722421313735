<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>内容管理</el-breadcrumb-item>
          <el-breadcrumb-item>品牌故事</el-breadcrumb-item>
          <el-breadcrumb-item>新增</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form label-width="100px" ref="info" :model="info">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">品牌信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col>
            <el-form-item
              label="品牌名称"
              :rules="[
                {
                  required: true,
                  message: '品牌名称不能为空',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                placeholder="请输入品牌名称"
                v-model="info.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="品牌图片"
              class="width_auto"
              :rules="[
                {
                  required: true,
                  message: '品牌图片不能为空',
                  trigger: 'blur',
                },
              ]"
            >
              <el-upload
                :headers="upload_headers"
                v-model="info.imageUrl"
                ref="upload"
                class="avatar-uploader"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadFile"
                :on-success="handleAvatarSuccess"
                :on-exceed="onExceed"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div>*建议尺寸：1035*606像素；图片大小不能超过200KB</div>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="品牌简介"
              :rules="[
                {
                  required: true,
                  message: '品牌简介不能为空',
                  trigger: 'blur',
                },
              ]"
              style="width: 70%"
            >
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="info.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item
              label="详情介绍"
              :rules="[
                {
                  required: true,
                  message: '详情介绍不能为空',
                  trigger: 'blur',
                },
              ]"
              class="width_auto_70"
            >
              <wangeditor
                @goods-content="goodsContent"
                :contents="info.commodityDetail"
              ></wangeditor>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="24">
          <span class="grid-divider"
            >关联商品 &nbsp; &nbsp;
            <el-button type="primary" icon="el-icon-plus" plain
              >添加商品</el-button
            ></span
          >
          <el-divider></el-divider>
        </el-col>
        <el-col>
          <template>
            <el-table
              :data="tableData"
              border
              style="width: 100%; text-align: center"
            >
              <el-table-column prop="date" label="序号"></el-table-column>
              <el-table-column prop="name" label="商品名称"></el-table-column>
              <el-table-column
                prop="address"
                label="商品分类"
              ></el-table-column>
              <el-table-column prop="" label="销售价格"></el-table-column>
              <el-table-column prop="" label="惠豆价格"></el-table-column>
              <el-table-column prop="" label="排序"></el-table-column>
              <el-table-column prop="" label="操作">
                <template>
                  <el-button type="danger" icon="el-icon-delete"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row>
        <el-col :span="12">
          <el-button type="primary" icon="el-icon-plus">保存到草稿箱</el-button>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button icon="el-icon-back" plain>取消</el-button>
          <el-button type="primary" icon="el-icon-plus">保存到草稿箱</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { baseUrl, staticUrl } from "@/plugins/api";
import wangeditor from "@/components/seller/wangeditor.vue";

export default {
  name: "qingwu",
  components: {
    wangeditor,
  },
  props: {},
  data() {
    return {
      info: {
        commodityDetail: "",
        name: "",
      },
      upload_headers: {},
      actionUploadUrl: "",
      imageUrl: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      upload: {
        param: { fileType: "mcategory" },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    resetForm(e) {
      this.$refs[e].resetFields();
    },
    submitForm(e) {
      // 验证表单
      this.$refs[e].validate((res) => {
        if (res) {
          let info = this.info;
          // 请求
          this.$post(this.$api.addIntegralCommodity, info).then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "添加成功！",
              });
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    handleAvatarSuccess() {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    // 文件删除
    handleRemove(file) {
      // console.log(file,this.$refs.upload);
      this.$refs.upload.handleRemove(file);
      let index = this.file_list.indexOf(file);
      this.file_list.splice(index, 1);
    },
    // 上传超过限制
    onExceed() {
      this.$message.error("商品展示图片不能超过5个");
    },
    uploadFile(option) {
      const formData = new FormData();
      formData.append("file", option.file);
      formData.append("fileType", this.upload.param.fileType);
      this.$postMultipart(this.$api.upload, formData).then((res) => {
        if (res.code == 1) {
          this.file_list.push({
            name: "",
            url: `${staticUrl}${res.data.filePath}${res.data.fileName}`,
          });
          this.$forceUpdate();
        }
      });
    },
    beforeAvatarUpload(file) {
      //文件上传之前调用做一些拦截限制
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 富文本编辑内容变化
    goodsContent(res) {
      this.info.commodityDetail = res;
    },
  },
  created() {
    this.actionUploadUrl = baseUrl + "system/file/upload";
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  line-height: 32px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 146px;
  line-height: 146px;
  text-align: center;
}

.avatar-upload {
  width: 146px;
  height: 146px;
}

.is_master {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 164px;
  text-align: center;
  display: none;
}

.goods_class_add_left {
  float: left;
  margin-right: 15px;
  // border:1px solid #e1e1e1;
  padding: 0 20px;
  border-radius: 5px;
  background: #f1f1f1;
}

.el-icon-delete {
  cursor: pointer;
}

.el-icon-delete:hover {
  color: red;
}
</style>
