var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qingwu"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("内容管理")]),_c('el-breadcrumb-item',[_vm._v("品牌故事")]),_c('el-breadcrumb-item',[_vm._v("新增")])],1)],1)]),_c('el-form',{ref:"info",attrs:{"label-width":"100px","model":_vm.info}},[_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("品牌信息")]),_c('el-divider')],1),_c('el-col',[_c('el-form-item',{attrs:{"label":"品牌名称","rules":[
              {
                required: true,
                message: '品牌名称不能为空',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"请输入品牌名称"},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}})],1)],1),_c('el-col',[_c('el-form-item',{staticClass:"width_auto",attrs:{"label":"品牌图片","rules":[
              {
                required: true,
                message: '品牌图片不能为空',
                trigger: 'blur',
              } ]}},[_c('el-upload',{ref:"upload",staticClass:"avatar-uploader",attrs:{"headers":_vm.upload_headers,"show-file-list":false,"before-upload":_vm.beforeAvatarUpload,"http-request":_vm.uploadFile,"on-success":_vm.handleAvatarSuccess,"on-exceed":_vm.onExceed},model:{value:(_vm.info.imageUrl),callback:function ($$v) {_vm.$set(_vm.info, "imageUrl", $$v)},expression:"info.imageUrl"}},[(_vm.imageUrl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',[_vm._v("*建议尺寸：1035*606像素；图片大小不能超过200KB")])],1)],1),_c('el-col',[_c('el-form-item',{staticStyle:{"width":"70%"},attrs:{"label":"品牌简介","rules":[
              {
                required: true,
                message: '品牌简介不能为空',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入内容"},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}})],1)],1),_c('el-col',[_c('el-form-item',{staticClass:"width_auto_70",attrs:{"label":"详情介绍","rules":[
              {
                required: true,
                message: '详情介绍不能为空',
                trigger: 'blur',
              } ]}},[_c('wangeditor',{attrs:{"contents":_vm.info.commodityDetail},on:{"goods-content":_vm.goodsContent}})],1)],1)],1)],1)]),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("关联商品 "),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","plain":""}},[_vm._v("添加商品")])],1),_c('el-divider')],1),_c('el-col',[[_c('el-table',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"prop":"date","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"name","label":"商品名称"}}),_c('el-table-column',{attrs:{"prop":"address","label":"商品分类"}}),_c('el-table-column',{attrs:{"prop":"","label":"销售价格"}}),_c('el-table-column',{attrs:{"prop":"","label":"惠豆价格"}}),_c('el-table-column',{attrs:{"prop":"","label":"排序"}}),_c('el-table-column',{attrs:{"prop":"","label":"操作"}},[[_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete"}},[_vm._v("删除")])]],2)],1)]],2)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"}},[_vm._v("保存到草稿箱")])],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[_c('el-button',{attrs:{"icon":"el-icon-back","plain":""}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"}},[_vm._v("保存到草稿箱")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }